import * as React from "react";
const Cancle = (props) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.8753 7.13749C22.7596 7.02161 22.6223 6.92968 22.4711 6.86695C22.3198 6.80422 22.1577 6.77193 21.994 6.77193C21.8303 6.77193 21.6682 6.80422 21.517 6.86695C21.3658 6.92968 21.2284 7.02161 21.1128 7.13749L15.0003 13.2375L8.88778 7.12499C8.77205 7.00926 8.63466 6.91746 8.48346 6.85483C8.33225 6.7922 8.17019 6.75996 8.00653 6.75996C7.84287 6.75996 7.68081 6.7922 7.5296 6.85483C7.3784 6.91746 7.24101 7.00926 7.12528 7.12499C7.00955 7.24072 6.91775 7.37811 6.85512 7.52931C6.79249 7.68052 6.76025 7.84258 6.76025 8.00624C6.76025 8.1699 6.79249 8.33196 6.85512 8.48317C6.91775 8.63437 7.00955 8.77176 7.12528 8.88749L13.2378 15L7.12528 21.1125C7.00955 21.2282 6.91775 21.3656 6.85512 21.5168C6.79249 21.668 6.76025 21.8301 6.76025 21.9937C6.76025 22.1574 6.79249 22.3195 6.85512 22.4707C6.91775 22.6219 7.00955 22.7593 7.12528 22.875C7.24101 22.9907 7.3784 23.0825 7.5296 23.1451C7.68081 23.2078 7.84287 23.24 8.00653 23.24C8.17019 23.24 8.33225 23.2078 8.48346 23.1451C8.63466 23.0825 8.77205 22.9907 8.88778 22.875L15.0003 16.7625L21.1128 22.875C21.2285 22.9907 21.3659 23.0825 21.5171 23.1451C21.6683 23.2078 21.8304 23.24 21.994 23.24C22.1577 23.24 22.3198 23.2078 22.471 23.1451C22.6222 23.0825 22.7595 22.9907 22.8753 22.875C22.991 22.7593 23.0828 22.6219 23.1454 22.4707C23.2081 22.3195 23.2403 22.1574 23.2403 21.9937C23.2403 21.8301 23.2081 21.668 23.1454 21.5168C23.0828 21.3656 22.991 21.2282 22.8753 21.1125L16.7628 15L22.8753 8.88749C23.3503 8.41249 23.3503 7.61249 22.8753 7.13749V7.13749Z"
      fill={props.fill || "white"}
    />
  </svg>
);
export default Cancle;
